/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("jquery-ui")
// require("bootstrap-datepicker");
require("chartkick");
require("chart.js");
require("jqtree");
require("jquery_nested_form");
require("bootstrap-select");
// require("jquery");

import 'bootstrap';
import "@fortawesome/fontawesome-free/css/all"

import Rails from "@rails/ujs"
window.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'select2'
import 'select2/dist/css/select2.css'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", () => {
    $('#category_group_ids').select2()
	$('#user_group_ids').select2()
	$('#media_file_group_ids').select2()
    $('#group_user_ids').select2()
    $('#group_alias_group_ids').select2()    
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    $('#assets').sortable({
        axis: 'y',
        handle: '.handle',
        update: function (e, ui) {
            $.post($(this).data('update-media-file'), $(this).sortable('serialize'))
        }
    });

    $('#flash_cards').sortable({
        axis: 'y',
        handle: '.handle',
        update: function (e, ui) {
            $.post($(this).data('update-flash-cards'), $(this).sortable('serialize'))
        }
    });

    

});

